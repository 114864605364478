import CommonButton from "@/components/common-button";
import { LOADING_IMAGE_URL } from "@/utils/constant";
import { Box, Center, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import { LOGO_LIST, TALLY_LINK } from "../constants";
import * as styles from "./index.module.less";

const HeroBanner = () => {
  return (
    <Center
      w="full"
      padding={{
        base: "40px 24px",
        md: "64px 40px",
        lg: "80px 96px",
        xl: "110px 80px",
        "2xl": "108px 160px 140px",
      }}
      bg="#FBF8FF"
    >
      <Flex
        maxW="web_max_w"
        gap={{
          base: "40px",
          xl: "80px",
        }}
        alignItems="center"
        flexDir={{
          base: "column",
          xl: "row",
        }}
      >
        <Flex
          flexDir="column"
          gap="40px"
          alignItems={{
            base: "center",
            xl: "flex-start",
          }}
          flexShrink="0"
          maxW={{
            base: "100%",
            xl: "560px",
          }}
        >
          <Flex
            flexDir="column"
            alignItems={{
              base: "center",
              xl: "flex-start",
            }}
          >
            <Text
              as="h1"
              my="0"
              textAlign={{
                base: "center",
                xl: "left",
              }}
            >
              Real-time <br />AI Copilot for
            </Text>
            <Flex
              w="full"
              flexDir="column"
              pos="relative"
              overflow="hidden"
              alignItems={{
                base: "center",
                xl: "flex-start",
              }}
            >
              {/* Placeholder */}
              <Box visibility="hidden">
                <Text
                  my="0"
                  as="h1"
                  bg="linear-gradient(90deg, #AD68FF 0%, #FD9ADE 44.02%)"
                  bgClip="text"
                >
                  Customer Success
                </Text>
              </Box>
              <Flex
                pos="absolute"
                top="0%"
                className={styles.words_container}
                flexDir="column"
                alignItems={{
                  base: "center",
                  xl: "flex-start",
                }}
              >
                <Box>
                  <Text
                    my="0"
                    as="h1"
                    bg="linear-gradient(90deg, #AD68FF 0%, #FD9ADE 44.02%)"
                    bgClip="text"
                    textAlign={{ base: "center", xl: "left" }}
                    className={styles.customer_text}
                  >
                    Customer Success
                  </Text>
                </Box>
                <Box>
                  <Text
                    as="h1"
                    my="0"
                    bg="linear-gradient(90deg, #7468FF 0%, #9AC1FD 100%)"
                    bgClip="text"
                    textAlign={{ base: "center", xl: "left" }}
                    className={styles.sales_text}
                  >
                    Sales
                  </Text>
                </Box>
                <Box>
                  <Text
                    my="0"
                    as="h1"
                    bg="linear-gradient(90deg, #AD68FF 0%, #FD9ADE 44.02%)"
                    bgClip="text"
                    textAlign={{ base: "center", xl: "left" }}
                    className={styles.customer_text}
                  >
                    Customer Success
                  </Text>
                </Box>
                <Box>
                  <Text
                    as="h1"
                    my="0"
                    bg="linear-gradient(90deg, #7468FF 0%, #9AC1FD 100%)"
                    bgClip="text"
                    textAlign={{ base: "center", xl: "left" }}
                    className={styles.sales_text}
                  >
                    Sales
                  </Text>
                </Box>
              </Flex>
            </Flex>
          </Flex>
          <Box boxShadow="0px 32px 48px #F0CEFF">
            <CommonButton
              className={styles.hero_btn}
              bg="linear-gradient(90deg, #E146F1 0%, #9047F1 100%)"
              content="Get early access"
              to={TALLY_LINK}
              data-gtm="signup_early_access_btn_hero_banner"
            />
          </Box>
          <Flex
            gap={{
              base: "24px",
              sm: "32px",
              lg: "40px",
            }}
            alignItems="center"
          >
            {LOGO_LIST.map((item, index) => {
              return (
                <Image
                  key={index}
                  src={item.src}
                  alt={item.alt}
                  h={{
                    base: "40px",
                    md: "48px",
                    lg: "64px",
                  }}
                  w={{
                    base: "40px",
                    md: "48px",
                    lg: "64px",
                  }}
                  objectFit="contain"
                />
              );
            })}
            <Image
              h={{
                base: "32px",
                sm: "40px",
                lg: "48px",
              }}
              src="https://a.storyblok.com/f/167495/156x53/bf1409630d/ph.svg"
              alt="G2 high performer badge"
            />
          </Flex>
        </Flex>
        <Center flex="1">
          <Image
            src="https://a.storyblok.com/f/167495/1200x901/21cd21144b/image-fake-door-testing-hero-banner.jpg"
            alt=""
            w={{
              base: "100%",
              xl: "640px",
            }}
            h={{
              base: "256px",
              sm: "360px",
              md: "480px",
            }}
            objectFit="contain"
          />
        </Center>
      </Flex>
    </Center>
  );
};

export default HeroBanner;
