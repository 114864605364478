import GatsbyLink from "@/components/commons/GatsbyLink";
import { Center, Flex, Image } from "@chakra-ui/react";
import React from "react";
import AirgramLogoSvg from "@/assets/images/LogoAirgramDefault.svg";
import * as styles from "./index.module.less";
import CommonButton from "@/components/common-button";
import { TALLY_LINK } from "../constants";

const CustomNavBar = () => {
  return (
    <Center
      top="0"
      left="0"
      pos="sticky"
      width="100%"
      zIndex="100"
      boxShadow="0 4px 8px 0 rgb(0 0 0 / 10%)"
      background="rgba(255, 255, 255, 0.75)"
      backdropFilter="blur(64px)"
      px={{
        base: "24px",
        sm: "40px",
        md: "40px",
        lg: "40px",
        xl: "80px",
        "2xl": "160px",
      }}
    >
      <Flex
        w="full"
        maxW="web_max_w"
        p="12px 0"
        justifyContent="space-between"
        alignItems="center"
      >
        <Center flexShrink="0">
          <GatsbyLink to="/">
            <Image
              src={AirgramLogoSvg}
              alt="Airgram logo"
              htmlWidth="112px"
              htmlHeight="24px"
              height={{
                base: "20px",
                sm: "24px",
                md: "24px",
                lg: "24px",
                xl: "24px",
                "2xl": "24px",
              }}
            />
          </GatsbyLink>
        </Center>
        <Center>
          <CommonButton
            data-gtm="signup_early_access_btn_nav"
            content="Get early access"
            to={TALLY_LINK}
          />
        </Center>
      </Flex>
    </Center>
  );
};
export default CustomNavBar;
