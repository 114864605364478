import Layout from "@/layout";
import CustomNavBar from "@/modules/real-time-ai/custom-nav-bar";
import FeatureList from "@/modules/real-time-ai/feature-list";
import HeroBanner from "@/modules/real-time-ai/hero-banner";
import LeftRightPart from "@/modules/real-time-ai/left-right-part";
import { Center } from "@chakra-ui/react";
import React from "react";
import * as styles from "./index.module.less";
import Header from "@components/head";
import CustomBottomNavBar from "@/modules/real-time-ai/custom-bottom-nav-bar";

export const Head = () => {
  return (
    <Header
      title="Airgram: real-time AI copilot tool for sales and customer success managers"
      description="Empower newcomers to sell or support like seasoned professionals! Save time and money."
    />
  );
};

const RealTimeAi = () => {
  return (
    <Layout
      isNewStyle
      px="0px"
      margin="0px"
      minW="100%"
      pt="0px"
      navMaxW="web_max_w"
      customNavBar={<CustomNavBar />}
      customBottomNavBar={<CustomBottomNavBar />}
    >
      <HeroBanner />
      {/* <FeatureList /> */}
      <LeftRightPart />
    </Layout>
  );
};

export default RealTimeAi;
