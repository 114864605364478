import { IBreakPoint } from "@/utils/type";
import { Center, ResponsiveValue, Text } from "@chakra-ui/react";
import * as React from "react";
import { CSSProperties } from "react";

interface IProp {
  id?: string;
  children?: React.ReactNode;
  blockTitle?: string | React.ReactNode;
  blockSubTitle?: string | React.ReactNode;
  blockBg?: string;
  pt?: object | string;
  pb?: object | string;
  titleContentGap?: string | ResponsiveValue<object>;
  other?: CSSProperties;
  maxW?: object | string;
}

const { memo } = React;

const BlokContainer = ({
  children,
  id,
  blockTitle,
  blockSubTitle,
  blockBg = "transparent",
  pt = {
    base: "64px",
    sm: "80px",
    md: "80px",
    lg: "80px",
    xl: "90px",
    "2xl": "100px",
  },
  pb = {
    base: "64px",
    sm: "80px",
    md: "80px",
    lg: "80px",
    xl: "90px",
    "2xl": "100px",
  },
  titleContentGap = {
    base: "40px",
    md: "48px",
    xl: "64px",
  },
  maxW = {
    base: "100%",
    sm: "100%",
    md: "100%",
    lg: "1120px",
    xl: "1440px",
    "2xl": "1600px",
  },
  other,
}: IProp) => {
  return (
    <Center
      id={id}
      w="full"
      pt={pt}
      pb={pb}
      px={{
        base: "24px",
        sm: "40px",
        md: "40px",
        lg: "40px",
        xl: "80px",
        "2xl": "160px",
      }}
      background={blockBg}
      style={{ ...other }}
    >
      <Center w="full" margin="auto" maxW={maxW} flexDir="column">
        {blockTitle &&
          (typeof blockTitle === "string" ? (
            <Text as="h2" style={{ margin: "0px" }} textAlign="center">
              {blockTitle}
            </Text>
          ) : (
            blockTitle
          ))}
        {blockSubTitle &&
          (typeof blockSubTitle === "string" ? (
            <Text
              as="p"
              style={{ margin: "0", marginTop: "24px" }}
              textAlign="center"
            >
              {blockSubTitle}
            </Text>
          ) : (
            blockSubTitle
          ))}
        <Center w="full" mt={blockTitle ? titleContentGap : {}}>
          {children}
        </Center>
      </Center>
    </Center>
  );
};

export default memo(BlokContainer);
