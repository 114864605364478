import { Text, Center, Flex, Box } from "@chakra-ui/react";
import styled from "@emotion/styled";
import React, { memo } from "react";

const LayoutWrapper = styled(Center)`
  @media (max-width: 992px) {
    flex-direction: column;

    .mc-graphic--content {
      text-align: center;
    }
  }
`;

interface IGraphicTextLayout {
  slot: any;
  title: string;
  gap?: any;
  children: any;
  placement?: "left" | "right";
}
const GraphicTextLayout: React.FC<IGraphicTextLayout> = ({
  slot,
  title,
  children,
  gap = {
    base: "24px",
    sm: "40px",
    md: "40px",
    lg: "40px",
    xl: "80px",
    "2xl": "80px",
  },
  placement = "left",
}) => (
  <LayoutWrapper
    gap={gap}
    width="100%"
    placement={placement}
    flexDirection={placement === "left" ? "row" : "row-reverse"}
  >
    <Center flex={1} width="100%" overflow="hidden" borderRadius="12px">
      {slot}
    </Center>

    <Flex
      flexDir="column"
      gap={{
        base: "12px",
        sm: "16px",
        lg: "24px",
      }}
      flex={1}
      width="full"
      maxW="800px"
      color="#220247"
    >
      <Text as="h2" my="0">
        {title}
      </Text>
      <Box
        fontWeight="500"
        fontSize={{
          base: "14px",
          sm: "16px",
          lg: "18px",
        }}
      >
        {children}
      </Box>
    </Flex>
  </LayoutWrapper>
);

export default memo(GraphicTextLayout);
