import { Center, Flex, Image, Link, Text } from "@chakra-ui/react";
import React from "react";
import * as styles from "./index.module.less";
import AirgramLogoSvg from "@/assets/images/LogoAirgramWhite.svg";
import { GatsbyLink } from "@/components/commons";
import { FOOTER_MEDIA_LINK } from "../constants";

const CustomBottomNavBar = () => {
  return (
    <Center
      w="full"
      p={{
        base: "24px",
        sm: "24px 40px",
        xl: "40px 80px",
        "2xl": "40px 160px",
      }}
      bg="#170032"
    >
      <Flex
        maxW="web_max_w"
        w="full"
        justifyContent="space-between"
        flexDir={{
          base: "column",
          md: "row",
        }}
        gap="24px"
      >
        <Flex
          gap={{
            base: "32px",
            md: "40px",
          }}
          alignItems="center"
        >
          <Center flexShrink="0">
            <GatsbyLink to="/">
              <Image
                src={AirgramLogoSvg}
                alt="Airgram logo"
                htmlWidth="112px"
                htmlHeight="24px"
                height={{
                  base: "20px",
                  sm: "24px",
                  md: "24px",
                  lg: "24px",
                  xl: "24px",
                  "2xl": "24px",
                }}
              />
            </GatsbyLink>
          </Center>
          <Flex gap="24px">
            {FOOTER_MEDIA_LINK.map((item) => {
              return (
                <Link href={item.link}>
                  <Image
                    opacity={0.5}
                    w="18px"
                    h="18px"
                    src={item.icon.src}
                    alt={item.icon.alt}
                    objectFit="contain"
                  />
                </Link>
              );
            })}
          </Flex>
        </Flex>
        <Flex>
          <Text
            lineHeight="24px"
            fontSize="16px"
            color="#DAD8D8"
            style={{ margin: "0" }}
            opacity="0.6"
          >
            © 2023 Mind Cruiser, Ltd. All rights reserved.
          </Text>
        </Flex>
      </Flex>
    </Center>
  );
};
export default CustomBottomNavBar;
