import CommonButton from "@/components/common-button";
import { Flex, List, ListItem, Text } from "@chakra-ui/react";
import React from "react";
import { FeatureItemWrapper } from "./left-right-part";
import YoutubeLogoImage from "@/assets/images/Youtube.svg";
import TwitterLogoImage from "@/assets/images/Twitter.svg";
import FacebookLogoImage from "@/assets/images/Facebook.svg";
import LinkedInLogo from "@/assets/images/LinkedIn.svg";

export const TALLY_LINK = "https://tally.so/r/wzNeDk";

export const LOGO_LIST = [
  {
    src: "https://a.storyblok.com/f/167495/x/b412a70a00/loog-aicpa-soc.svg",
    alt: "SOC 2 icon",
  },
  {
    src: "https://a.storyblok.com/f/167495/64x64/25906da396/logo-gdpr.svg",
    alt: "GDPR icon",
  },
  {
    src: "https://a.storyblok.com/f/167495/x/7285b203e8/highperformer.svg",
    alt: "G2 high performer badge",
  },
];

export const FEATURE_LIST = [
  {
    title: "Real-time monologue duration and talk ratio detection",
    desc: "Airgram helps encourage your customers to communicate more, which can lead to increased profits and business success.",
    video: {
      src: "https://a.storyblok.com/f/167495/x/f77a0ea3a3/fake-door-testing-animation-1-monologue.mp4",
    },
    delay: 5000,
  },
  {
    title: "AI note-taker, hands-free.",
    desc: "Airgram takes notes for you based on your own template, allowing you to focus on the conversation.",
    video: {
      src: "https://a.storyblok.com/f/167495/x/8e8ae60fdf/fake-door-testing-animation-2-ai-notes.mp4",
    },
    delay: 9000,
  },
  {
    title: "AI coaching, turn rookies into veterans.",
    desc: "Airgram helps you get real-time, AI-based suggestions for your conversations, tailored to your own playbook.",
    video: {
      src: "https://a.storyblok.com/f/167495/x/7b10a1ee7f/fake-door-testing-animation-3-suggested-answer.mp4",
    },
    delay: 9000,
  },
];

export const FEATURE_DATA = [
  {
    title: "AI coaching, turn rookies into veterans",
    desc: (
      <FeatureItemWrapper>
        <Text style={{ margin: "0" }}>
          Airgram helps you get real-time, AI-based suggestions for your
          conversations, tailored to your own playbook.
        </Text>
      </FeatureItemWrapper>
    ),
    media: {
      type: "video",
      src: "https://a.storyblok.com/f/167495/x/647fa774c0/fake-door-testing-animation-3-suggested-answer.mp4",
      alt: "",
    },
    dataGtm: "signup_early_access_btn_suggested_answer",
  },
  {
    title: "AI note-taker, hands-free",
    desc: (
      <FeatureItemWrapper>
        <Text style={{ margin: "0" }}>
          Airgram takes notes for you based on your own template, allowing you
          to focus on the conversation.
        </Text>
      </FeatureItemWrapper>
    ),
    media: {
      type: "video",
      src: "https://a.storyblok.com/f/167495/x/095e886a7b/fake-door-testing-animation-2-ai-notes.mp4",
      alt: "",
    },
    dataGtm: "signup_early_access_btn_ai_notes",
  },

  {
    title: "Real-time monologue duration and talk ratio detection",
    desc: (
      <FeatureItemWrapper>
        <Text style={{ margin: "0" }}>
          Airgram helps encourage your customers to talk more, which can lead to
          better client engagement and more deals won.
        </Text>
      </FeatureItemWrapper>
    ),
    media: {
      type: "video",
      src: "https://a.storyblok.com/f/167495/x/ee3cd8369c/fake-door-testing-animation-1-monologue.mp4",
      alt: "",
    },
    dataGtm: "signup_early_access_btn_monologue",
  },
  {
    title: "Sync to your CRM after the call",
    desc: (
      <FeatureItemWrapper>
        <Text style={{ margin: "0" }}>
          Airgram integrates with the CRM you use and syncs meeting notes with
          just one click.
        </Text>
      </FeatureItemWrapper>
    ),
    media: {
      type: "video",
      src: "https://a.storyblok.com/f/167495/x/9aa6dc8417/fake-door-testing-animation-4-send-to-crm.mp4",
      alt: "",
    },
    dataGtm: "signup_early_access_btn_send_to_crm",
  },
  {
    title: "Improve performance with data insights",
    desc: (
      <FeatureItemWrapper>
        <List listStyleType="disc" pl="2em">
          <ListItem>Call sentiment</ListItem>
          <ListItem>Monologue duration</ListItem>
          <ListItem>Talk ratio</ListItem>
          <ListItem>Talk speed</ListItem>
          <ListItem>Features mentioned most</ListItem>
          <ListItem>Competitor mentioned most</ListItem>
        </List>
      </FeatureItemWrapper>
    ),
    media: {
      type: "image",
      src: "https://a.storyblok.com/f/167495/1600x1200/1ea3a4ad69/data-insight.png",
      alt: "",
    },
    dataGtm: "signup_early_access_btn_data_insight",
  },
];

export const FOOTER_MEDIA_LINK = [
  {
    icon: {
      src: TwitterLogoImage,
      alt: "Twitter logo",
    },
    link: "https://twitter.com/AirgramIO",
  },
  {
    icon: {
      src: FacebookLogoImage,
      alt: "Facebook logo",
    },
    link: "https://www.facebook.com/AirgramIO",
  },
  {
    icon: {
      src: YoutubeLogoImage,
      alt: "Youtube logo",
    },
    link: "https://www.youtube.com/channel/UCGaJo0-OF4bJ_JyWiN1f6Ow",
  },
  {
    icon: {
      src: LinkedInLogo,
      alt: "linkedin logo",
    },
    link: "https://www.linkedin.com/company/airgram",
  },
];
