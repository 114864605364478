import BlokContainer from "@/components/blok-container";
import CommonButton from "@/components/common-button";
import GraphicText from "@/layout/GraphicText";
import { Box, Flex, List, ListItem, Image, Text } from "@chakra-ui/react";
import React from "react";
import { FEATURE_DATA, TALLY_LINK } from "../constants";
import * as styles from "./index.module.less";

export const FeatureItemWrapper = ({ children }) => {
  return (
    <Flex w="full" flexDir="column" alignItems="flex-start">
      {children}
    </Flex>
  );
};

const LeftRightPart = () => {
  return (
    <BlokContainer pb="0px" pt="0px" maxW="web_max_w">
      <Flex
        w="full"
        gap={{ base: "40px", md: "80px", lg: "120px" }}
        pb={{
          base: "64px",
          sm: "80px",
          md: "80px",
          lg: "120px",
          xl: "120px",
          "2xl": "160px",
        }}
        flexDirection="column"
        pt={{
          base: "64px",
          sm: "80px",
          lg: "120px",
          "2xl": "160px",
        }}
      >
        {FEATURE_DATA.map((item, index) => {
          return (
            <GraphicText
              key={item.title}
              slot={
                <Box w="full" maxW="800px">
                  <Box
                    w="full"
                    h="0px"
                    pt="calc(100% * (3 / 4))"
                    pos="relative"
                  >
                    {item.media.type === "video" ? (
                      <video
                        className={styles.media_container}
                        src={item.media.src}
                        autoPlay
                        loop
                        muted
                      />
                    ) : (
                      <Image
                        className={styles.media_container}
                        src={item.media.src}
                        alt={item.media.alt}
                        objectFit="contain"
                      />
                    )}
                  </Box>
                </Box>
              }
              title={item.title}
              placement={index % 2 ? "left" : "right"}
            >
              <Flex
                w="full"
                flexDir="column"
                gap={{
                  base: "16px",
                  sm: "24px",
                  lg: "40px",
                }}
                alignItems="flex-start"
                mt={{
                  base: "16px",
                  sm: "24px",
                }}
              >
                {item.desc}
                <CommonButton
                  data-gtm={item.dataGtm}
                  to={TALLY_LINK}
                  content="Get early access"
                />
              </Flex>
            </GraphicText>
          );
        })}
      </Flex>
    </BlokContainer>
  );
};
export default LeftRightPart;
