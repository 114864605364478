import { Center, Link } from "@chakra-ui/react";
import React from "react";

interface IProp {
  content: string;
  to: string;
  padding?: any;
  bg?: string;
  color?: string;
  target?: string;
  borderRadius?: any;
  isShowHoverActive?: boolean;
  hoverBg?: string;
  activeBg?: string;
  gtmId?: string;
  children?: any;
  className?: string;
  [key: string]: any;
}

const CommonButton = ({
  to,
  content,
  target = "_blank",
  bg = "#9F2AEC",
  color = "#ffffff",
  padding = {
    base: "10px 20px",
    lg: "12px 24px",
  },
  borderRadius = {
    base: "6px",
    sm: "8px",
  },
  hoverBg = "#7C1AC7",
  activeBg = "#5C0DA1",
  isShowHoverActive = false,
  gtmId,
  children,
  className,
  ...other
}: IProp) => {
  return (
    <Link
      href={to}
      target={target}
      _hover={{
        textDecor: "none",
      }}
    >
      <Center
        data-gtm={gtmId}
        p={padding}
        fontWeight="600"
        fontSize={{
          base: "14px",
          sm: "16px",
          lg: "18px",
        }}
        className={className}
        color={color}
        bg={bg}
        _hover={{
          bg: isShowHoverActive ? hoverBg : bg,
        }}
        _active={{
          bg: isShowHoverActive ? activeBg : bg,
        }}
        borderRadius={borderRadius}
        {...other}
      >
        {children}{content}
      </Center>
    </Link>
  );
};

export default CommonButton;
